// Classes
import FieldsOptions         from '@/Classes/Records/FieldsOptions'
import { ItemMissingFields } from '@/Classes/Records/ItemMissingFields'
import { BuilderManager }    from '@/Classes/Responses/BuilderManager'

// Constants
import { ActionFields } from '@/Constants/Global/ActionFields'
import { AppValues }    from '@/Constants/Global/AppValues'
import { Module4  }     from '@/Constants/Modules/Module4'

// Store
import Store from '@/Store/Global/Default'

// Namespace Export
export namespace View41DataParsers {
	export function AddonsParser(data: Array<any>, includeStorages = true, includeActions = true) {
		// Clase Constructora.
		const bm = new BuilderManager(data, includeActions ? ActionFields.Edit : undefined)
		const { fields, items, actions } = bm.getElements()

		// Eliminar los campos que no queremos mostrar en la tabla.
		bm.removeFields(fields, [
			'_idCheckListAddons', 'storages'
		])

		// Aplicar opciones a los campos.
		bm.parseFieldsOptions(fields, new FieldsOptions()
			.add('addonCode', 'Código').isSortable().setAlign(null, 'center').setSearchKey('code')
			.add('addonName', 'Descripción').isSortable().setSearchKey('name')
			.add('addonTypeEquipment', 'Tipo Equipo').isSortable().setAlign(null, 'center').setSearchKey('typeEquipment')
			.add('addonGroupArticle', 'Grupo Articulo').isSortable().setAlign(null, 'center').setSearchKey('groupArticle')
			.add('addonAttributes', 'Preguntas').setSearchKey('attributes')
			.add('mStorages', 'Plantas').setSearchKey('company.name')
			.add('addonIsForClient', 'Exclusivo Cliente').setAlign(null, 'center').setSearchKey('isForClient')
		)

		// Eliminar los campos que no queremos mostrar en la tabla.
		if (!includeStorages) bm.removeFields(fields, [
			'mStorages'
		])

		// Aplicar un nuevo Orden a todas las columnas visibles.
		bm.rearrangeFields(fields, [
			'addonCode', 'addonName', 'addonTypeEquipment', 'addonGroupArticle', 'addonAttributes', 'mStorages', 'addonIsForClient'
		])

		// Aplicar un valor a los campos e items si no estan presentes.
		bm.parseItemsMissingFields(items, new ItemMissingFields()
			.add('mStorages')
		)

		// Definir los formatos que serán aplicados al valor de la celda.
		bm.parseItemsFormatters(items, {
			addonsAttributes: (values: Array<string>) => {
				return values.sort()
			},

			addonGroupArticle: (value: string) => {
				return value === Module4.M40.Defaults.TypeActiveNames.FACILITY
					? Module4.M40.Defaults.TypeActiveTranslationNames.FACILITY.toUpperCase()
					: value
			},

			addonIsForClient: (value: boolean) => {
				return value ? 'Sí' : 'No'
			},

			mStorages: (_: void, item: any) => {
				if (Array.isArray(item.storages) && item.storages.length > 0) {
					return item.storages.map((x: any) => x.storageName)
				}
				return AppValues.Strings.DEFAULT_EMPTY_STRING
			},
		})

		// Retornar Objeto con los Campos, Items y Acciones.
		return { fields, items, actions }
	}

	export function AddonsAssociationsParser(data: Array<any>) {
		// Clase Constructora.
		const bm = new BuilderManager(data)
		const { fields, items } = bm.getElements()

		// Eliminar los campos que no queremos mostrar en la tabla.
		bm.removeFields(fields, ['_idCheckListAddons', '_idStorage','_idCompany', 'addonIsForClient','isForClient'])
	
		// Aplicar opciones a los campos.
		bm.parseFieldsOptions(fields, new FieldsOptions()
			.add('addonCode', 'Código').isSortable().setAlign('center', 'center').setSearchKey('code')
			.add('addonName', 'Descripción').isSortable().setAlign('center', 'center').setSearchKey('name')
			.add('addonTypeEquipment', 'Tipo Equipo').isSortable().setAlign('center', 'center').setSearchKey('typeEquipment')
			.add('addonGroupArticle', 'Grupo Articulo').isSortable().setAlign('center', 'center').setSearchKey('groupArticle')
			.add('storageCode', 'Código Planta').isSortable().setAlign('center', 'center').setSearchKey('storage.code')
			.add('storageName', 'Planta').isSortable().setAlign('center', null).setSearchKey('storage.name')
			.add('companyName', 'Cliente').isSortable().setAlign('center', null).setSearchKey('company.name')
			.add('addonAttributes', 'Listado Evaluación').setAlign('center', null).setSearchKey('attributes')
			//.add('addonIsForClient', '¿Exclusivo Cliente?').isSortable().setAlign('center', 'center').setSearchKey('isForClient')
		)
	
		// Aplicar un nuevo Orden a todas las columnas visibles.
		bm.rearrangeFields(fields, [
			'addonCode', 'addonName', 'addonTypeEquipment', 'addonGroupArticle', 'storageCode', 'storageName', 'companyName', 'addonAttributes', 'addonIsForClient'
		])

		// Definir los formatos que serán aplicados al valor de la celda.
		bm.parseItemsFormatters(items, {
			addonIsForClient: (value: boolean) => {
				return value ? 'Sí' : 'No'
			},

			companyName: (value: string) => {
				return value || AppValues.Strings.DEFAULT_EMPTY_STRING
			},

			storageCode: (value: string) => {
				return value || AppValues.Strings.DEFAULT_EMPTY_STRING
			},

			storageName: (value: string) => {
				return value || AppValues.Strings.DEFAULT_EMPTY_STRING
			}
		})
	
		// Retornar Objeto con los Campos, Items y Acciones.
		return { fields, items }
	}

	export function AddonsStoragesParser(data: Array<any>, _actions?: any) {
		// Clase Constructora.
		const bm = new BuilderManager(data, _actions || undefined)
		const { fields, items, actions } = bm.getElements()

		// Eliminar los campos que no queremos mostrar en la tabla.
		bm.removeFields(fields, [
			// Provenientes de Endpoint que obtiene todas las 'Plantas'.
			'_idTechnical', '_idUserAdmin', 'address', 'code', 'codeLocation', 'commune', 'contacts', 'equipments', 'isValid',
			'name', 'region', 'technicalName', 'userAdminName',

			// Provenientes de Endpoint que enlista los 'Formularios' propios del Modulo.
			'_idCompany', '_idStorage'
		])

		// Aplicar opciones a los campos.
		bm.parseFieldsOptions(fields, new FieldsOptions()
			.add('storageCode', 'Código').isSortable().setAlign(null, 'center')
			.add('storageName', 'Planta').isSortable()
			.add('storageAddress', 'Dirección').isSortable()
			.add('companyName', 'Cliente').isSortable()
			.add('storageCommune', 'Comuna').setAlign(null, 'center')
		)

		// Aplicar un nuevo Orden a todas las columnas visibles.
		bm.rearrangeFields(fields, [
			'storageCode', 'storageName', 'storageAddress', 'companyName', 'storageCommune'
		])

		// Aplicar un valor a los campos e items si no estan presentes.
		bm.parseItemsMissingFields(items, new ItemMissingFields()
			.add('storageCode', '')
			.add('storageName', '')
			.add('storageAddress', '')
			.add('companyName', '')
			.add('storageCommune', '')
		)

		// Definir los formatos que serán aplicados al valor de la celda.
		bm.parseItemsFormatters(items, {
			storageAddress: (value: string, item: any) => {
				return value || item?.address || AppValues.Strings.DEFAULT_EMPTY_STRING
			},

			storageCode: (value: string, item: any) => {
				return value || item?.code || AppValues.Strings.DEFAULT_EMPTY_STRING
			},

			storageCommune: (value: string) => {
				return value || AppValues.Strings.DEFAULT_EMPTY_STRING
			},

			storageName: (value: string, item: any) => {
				return value || item?.name || AppValues.Strings.DEFAULT_EMPTY_STRING
			}
		})

		// Retornar Objeto con los Campos, Items y Acciones.
		return { fields, items, actions }
	}

	export function FacilitiesParser(data: Array<any>, includeActions = true) {
		// Clase Constructora.
		const bm = new BuilderManager(data, includeActions ? ActionFields.Edit : undefined)
		const { fields, items, actions } = bm.getElements()
	
		// Eliminar los campos que no queremos mostrar en la tabla.
		bm.removeFields(fields, [
			'_idInstallation', 'typeActive', 'groupArticle', 'storages'
		])
	
		// Aplicar opciones a los campos.
		bm.parseFieldsOptions(fields, new FieldsOptions()
			.add('code', 'Código Instalación').isSortable().setAlign('center', 'center')
			.add('name', 'Descripción').isSortable()
			.add('mStorages', 'Plantas').isSortable().setSearchKey('company.name').setSearchKey('storage.name')
			.add('isValid', '¿Esta Activa?').setAlign('center', 'center').isSortable().setSearchKey('isValid')
		)
	
		// Aplicar un nuevo Orden a todas las columnas visibles.
		bm.rearrangeFields(fields, [
			'code', 'name', 'mStorages', 'isValid'
		])
	
		// Aplicar un valor a los campos e items si no estan presentes.
		bm.parseItemsMissingFields(items, new ItemMissingFields()
			.add('mStorages')
		)
	
		// Definir los formatos que serán aplicados al valor de la celda.
		bm.parseItemsFormatters(items, {
			groupArticle: (v: string) => {
				return v === AppValues.ArticleGroups.FACILITY ? 'Instalación' : v
			},
	
			isValid: (v: boolean) => {
				return v ? 'Sí' : 'No'
			},

			mStorages: (_: void, item: any) => {
				if (Array.isArray(item.storages) && item.storages.length > 0) {
					return item.storages.map((x: any) => x.storageName)
				}
				return AppValues.Strings.DEFAULT_EMPTY_STRING
			}
		})
	
		// Retornar Objeto con los Campos, Items y Acciones.
		return { fields, items, actions }
	}

	export function FacilityAssociationsParser(data: Array<any>) {
		// Clase Constructora.
		const bm = new BuilderManager(data)
		const { fields, items } = bm.getElements()

		// Eliminar los campos que no queremos mostrar en la tabla.
		bm.removeFields(fields, [
			'_idInstallation', '_idStorage', '_idCompany', 'storageCommune'
		])
	
		// Aplicar opciones a los campos.
		bm.parseFieldsOptions(fields, new FieldsOptions()
			.add('installationCode', 'Código').isSortable().setAlign('center', 'center').setSearchKey('code')
			.add('installationName', 'Descripción').isSortable().isSortable().setAlign('center', 'center').setSearchKey('name')
			.add('installationGroupArticle', 'Tipo Activo').isSortable().isSortable().setAlign('center', 'center').setSearchKey('groupArticle')
			.add('storageCode', 'Código Planta').isSortable().isSortable().setAlign('center', 'center').setSearchKey('storage.code')
			.add('storageName', 'Planta').isSortable().isSortable().setAlign('center', 'center').setSearchKey('storage.name')
			.add('companyName', 'Cliente').isSortable().isSortable().setAlign('center', 'center').setSearchKey('company.name')
			.add('storageAddress', 'Dirección').isSortable().isSortable().setAlign('center', 'center').setSearchKey('storage.address.name')
			.add('installationIsValid', '¿Esta Activa?').isSortable().isSortable().setAlign('center', 'center').setSearchKey('isValid')
		)
	
		// Aplicar un nuevo Orden a todas las columnas visibles.
		bm.rearrangeFields(fields, [
			'installationCode', 'installationName', 'installationGroupArticle', 'storageCode', 'storageName', 'companyName', 'storageAddress', 'installationIsValid'
		])

		// Definir los formatos que serán aplicados al valor de la celda.
		bm.parseItemsFormatters(items, {
			installationGroupArticle: (value: string) => {
				if (!value) return AppValues.Strings.DEFAULT_EMPTY_STRING
				const M40Defaults = Module4.M40.Defaults
				return value.toUpperCase() === M40Defaults.TypeActiveNames.FACILITY.toUpperCase() ? M40Defaults.TypeActiveTranslationNames.FACILITY : value
			},

			installationIsValid: (value: boolean) => {
				return value ? 'Sí' : 'No'
			},

			storageAddress: (value: boolean) => {
				return value || AppValues.Strings.DEFAULT_EMPTY_STRING
			}
		})
	
		// Retornar Objeto con los Campos, Items y Acciones.
		return { fields, items }
	}
}