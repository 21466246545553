// ./
import { parseItemsResponse } from './response.parser'

// Classes
import { ActionField }      from '@/Classes/Records/ActionField'
import { DataParsers }      from '@/Classes/Responses/DataParsers'
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'

// Components
import DataTable  from '@/Components/Global/DataTable/template.vue'
import MenuBar    from '@/Components/Global/MenuBar/template.vue'
import PopupTable from '@/Components/Global/PopupTable/template.vue'

// Component (Refs)
import { DataTableRef }  from '@/Components/Global/DataTable/component'
import { MenuBarRef }    from '@/Components/Global/MenuBar/component'
import { PopupTableRef } from '@/Components/Global/PopupTable/component'

// Constants
import { Component } from '@/Constants/Global/Component'
import { Module4 }   from '@/Constants/Modules/Module4'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinBase      from '@/Mixins/MixinBase'
import MixinComponent from '@/Mixins/MixinComponent'

// Store
import Store from '@/Store/Global/Default'
import { View41DataParsers } from '@/Views/Modules/4/41/response.parser'
import { Server } from '@/Constants/Global/Server'

// Component Extend
const AddonSetup = VueMixins(MixinBase, MixinComponent).extend({
	name: 'AddonSetup',

	components: {
		DataTable,
		MenuBar,
		PopupTable
	},

	props: {
		show: Boolean
	},

	data: function() {
		return {
			states: {
				_id: undefined,
				action: Component.Actions.INSERT,
				items: [],
				itemIndex: -1,
				itemName: '',
				menuBarSelection: Module4.M41.Defaults.MenuBarKeyOptions.EVALUATIONS,
				showItemsControls: true,
				showMenuBar: true,
				showPopupTable: false,
				storages: [],
				title: 'Listado de Evaluaciones'
			}
		}
	},

	mounted: function() {
		// Establecer los parametros de los componentes 'DataTable'.
		this._dataTable.setStates<DataTableRef['states']>({ showControls: false })
		this._dataTable.setPermission('NEW_BUTTON', true)
		
		// Establecer los parametros de los componentes 'PopupTable/DataTable'.
		this._popupTable._dataTable.setStates<DataTableRef['states']>({ isLocalSearch: false, showExportButtons: false })
		this._popupTable._dataTable.setMaxHeight(668)
		this._popupTable._dataTable.setSelectable(true)

		// Establecer los elementos del componente 'MenuBar'.
		this._menuBar.setItems(Module4.M41.Collections.MenuBarOptions)
		this._menuBar.setStates<MenuBarRef['states']>({ selected: Module4.M41.Defaults.MenuBarKeyOptions.EVALUATIONS })
	},

	computed: {
		_dataTable: function(): DataTableRef {
			return this.$refs.dataTable as DataTableRef
		},

		_getButtonClass: function(): string {
			return this.states.itemIndex === -1 ? 'btn-green' : 'btn-orange'
		},

		_getButtonText: function(): string {
			return this.states.itemIndex === -1 ? 'Agregar' : 'Actualizar'
		},

		_getSubmitButtonText: function(): string {
			return this.states.action === Component.Actions.INSERT ? 'Crear' : 'Actualizar'
		},

		_menuBar: function(): MenuBarRef {
			return this.$refs.menuBar as MenuBarRef
		},

		_popupTable: function(): PopupTableRef {
			return this.$refs.popupTable as PopupTableRef
		}
	},

	methods: {
		_addNewItem: function() {
			const { itemIndex, itemName } = this.states
			if (itemIndex === -1) this.states.items.push({ name: this.states.itemName || 'Item Evaluación...' })
			else this.states.items.splice(itemIndex, 1, { name: itemName })

			this._clearEdit()
			this.parseItems()
		},

		_clearEdit: function() {
			this.setStates<AddonSetupRef['states']>({ itemIndex: -1, itemName: '' })
		},

		_fetchStorages: async function() {
			const response = await Store.dispatch('fetchStorages')
			return response
		},

		_initPermissions: function() {
			this._dataTable.setPermission('ACTION_EDIT', true)
			this._dataTable.setPermission('ACTION_DELETE', true)
		},

		/* <=================|==============================|=================> */
		/* <=================| PUBLIC DECLARATION FUNCTIONS |=================> */
		/* <=================|==============================|=================> */

		clear: function() {
			// Selección por Defecto.
			const selected = Module4.M41.Defaults.MenuBarKeyOptions.EVALUATIONS

			// Reiniciar las propiedades de estado.
			this.states = {
				_id: undefined,
				action: Component.Actions.INSERT,
				items: [],
				itemIndex: -1,
				itemName: '',
				menuBarSelection: selected,
				showItemsControls: true,
				showMenuBar: true,
				showPopupTable: false,
				storages: [],
				title: 'Listado de Evaluaciones'
			}

			// Reiniciar la selección del 'MenuBar'.
			this._menuBar.setStates<MenuBarRef['states']>({ selected })
			this.updateDataTableControls(selected)
		},

		parseItems: function() {
			const _items = this.states.items
			if (_items.length > 0) {
				const { fields, items, actions } = parseItemsResponse(_items)
				this._dataTable.setElements(fields, items, actions)
				return
			}
			this._dataTable.clearData()
		},

		parseStorages: function() {
			const { storages } = this.states
			if (storages.length > 0) {
				// Acción para permitir remover la Ubicación de la Tabla.
				const _actions = [
					new ActionField('actions', 'Eliminar')
						.addItem('delete', 'icon').setIcon('times-circle').setPermission('ACTION_DELETE').setVariant('red')
				]
				
				// Mostrar los datos en la Tabla.
				// const SummaryParser = DataParsers.Storages.GetSummaryParser(this._dataTable.states.stacked)
				const { fields, items, actions } = View41DataParsers.AddonsStoragesParser(storages, _actions)
				this._dataTable.setElements(fields, items, actions)
				return
			}
			this._dataTable.clearData()
		},

		updateDataTableControls: function(selected: number) {
			this._dataTable.setStates<DataTableRef['states']>({
				showControls: selected === Module4.M41.Defaults.MenuBarKeyOptions.STORAGES
			})
		},

		updateDataTableWrapperElements: function(selected: number) {
			const isEvaluationsSelected = selected === Module4.M41.Defaults.MenuBarKeyOptions.EVALUATIONS
			this.setStates<AddonSetupRef['states']>({
				menuBarSelection: selected,
				showItemsControls: isEvaluationsSelected,
				title: isEvaluationsSelected ? 'Listado de Evaluaciones' : 'Ubicaciones Asociadas'
			})
		},

		onASButtonClick: function(key: string) {
			DevelopmentTools.printWarn('[AddonSetup]:onASButtonClick() event triggered')
			this.$emit('onASButtonClick', key)
		},

		onDTButtonClick: function(key: string, { index, item }: any) {
			if (key === 'edit') {
				this.setStates<AddonSetupRef['states']>({ itemIndex: item.num - 1, itemName: item.name })
			}
			else if (key === 'delete') {
				const { menuBarSelection } = this.states
				if (menuBarSelection === Module4.M41.Defaults.MenuBarKeyOptions.EVALUATIONS) {
					this.states.items.splice(item.num - 1, 1)
					this.parseItems()
				}
				else if (menuBarSelection === Module4.M41.Defaults.MenuBarKeyOptions.STORAGES) {
					this.states.storages.splice(index, 1)
					this.parseStorages()
				}
			}
		},

		onDTNewButtonClick: async function(preventShowPopupTable: boolean, page = 1, forceRefresh = false) {
			// Validaciones Opcionales según Casos.
			const { _dataTable } = this._popupTable
			if (!preventShowPopupTable) this.setStates<AddonSetupRef['states']>({ showPopupTable: true })
			if (forceRefresh) _dataTable.clearAll()
			_dataTable.setFetchingState()

			// Actualizar el titulo al componente 'PopupTable'.
			this._popupTable.setTitle('Selección Ubicación')

			// Eliminar los Registros si se fuerza una Actualización.
			if (forceRefresh) Store.commit('destroyStorages')
			
			// Aplicar Registros con Paginación.
			const response = await Store.dispatch('fetchStoragesForPage', { forceRefresh, page })
			const SummaryParser = DataParsers.Storages.GetSummaryParser(_dataTable.states.stacked)
			const { fields, items } = SummaryParser(response.data)
			_dataTable.updateElementsAndPagination(response.totalPages, fields, items)

			// Aplicar Orden para Columna especifica.
			_dataTable.resetEmptyText()
			_dataTable.sortOrder('code', 'asc')
		},

		onMBItemClick: function(selected: number) {
			// Cambios según el elemento seleccionado del 'MenuBar'.
			this.updateDataTableWrapperElements(selected)

			// Actualizar los Controles del 'DataTable'.
			this.updateDataTableControls(selected)

			// El contenido del 'DataTable' debe mutar cuando se seleccione un elemento del 'MenuBar'.
			const isEvaluationsSelected = selected === Module4.M41.Defaults.MenuBarKeyOptions.EVALUATIONS;
			(isEvaluationsSelected) ? this.parseItems() : this.parseStorages()
		},

		onPTClose: function() {
			DevelopmentTools.printWarn('[AddonSetup]:onPTClose event triggered')
			this.setStates<AddonSetupRef['states']>({ showPopupTable: false })
		},

		onPTCurrentSearchPaginationChanged: function(page: number, searchParams: any) {
			this.onPTSearchButtonClicked(searchParams, page)
		},

		onPTPaginationChanged: async function(page: number) {
			this.onDTNewButtonClick(false, page)
		},

		onPTRefreshButtonClick: function() {
			this.onDTNewButtonClick(false, 1, true)
		},

		onPTSearchButtonClicked: function(searchParams: any, page = 1) {
			// Parametros.
			const { _dataTable } = this._popupTable
			const { _idAdminCompany } = Store.getters.getStoredUser
			const { searchKey, searchValue } = searchParams
			const SummaryParser = DataParsers.Storages.GetSummaryParser(_dataTable._isStacked)
			
			// Cambio de Estado de Carga.
			_dataTable.setFetchingState()

			// Ejecución de Endpoint Buscar.
			_dataTable.doInputSearch(Server.Routes.Storages.GetStoragesBySearchFilter, SummaryParser,
				{
					_idAdminCompany,
					itemsPerPage: _dataTable.itemsPerPage,
					page,
					searchKey,
					searchValue,
				},
				(response) =>  response.data.body[0]
			)
		},

		onRowDoubleClick: function({ item }: any) {
			DevelopmentTools.printWarn('[AddonSetup]:onRowDoubleClick():Event triggered')
			this.setStates<AddonSetupRef['states']>({ showPopupTable: false })

			// Validar que no se ingresen multiples copias de una misma ubicación.
			const { storages } = this.states
			const found = storages.find((x: any) => x._idStorage === item._idStorage)

			// Terminar ejecución si la 'Planta' ya fue ingresada.
			if (found) this.showToast('Ubicación ya ingresada', `La ubicación ${ item.code } ya se encuentra ingresada en la tabla!`, 'warning')
			else {
				this.states.storages.push(item)
				this.parseStorages()
			}
		}
	}
})

// Exports
export default AddonSetup
export type AddonSetupRef = InstanceType<typeof AddonSetup>